import React from "react";
import {Rate, Space} from "antd";
import Title from "antd/es/typography/Title";
import {useDispatch, useSelector} from "react-redux";
import {formFeedbackSelector, updateRate} from "../form/FormFeedbackSlice";
import {envSelector} from "../env/EnvSlice";

export function Question(props)
{
    const dispatch = useDispatch();
    const {form} = useSelector(formFeedbackSelector);

    const handleRateChange = (value) => {
        // console.log('-------> change');
        // event.preventDefault();
        dispatch(updateRate({
            questionIndex: props.questionIndex,
            value: value,
        }))
    }

    // console.log("Question: " + props);


    return (
        <div>
            <Space direction="vertical" size="large">
                <Title>
                    {/*Как Вы цениваете работу рецепции?*/}
                    {/*{props.question.text}*/}
                    {form.questions[props.questionIndex].content}
                </Title>
                <Rate style={{fontSize:'2rem'}}
                      onChange={handleRateChange}
                      value={form.questions[props.questionIndex].rate}/>
            </Space>
        </div>
    )
}