import React from "react"
import {Button, Checkbox, Col, Form, Modal, Rate, Row, Space} from "antd";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import {formFeedbackSelector} from "../form/FormFeedbackSlice";
import {envSelector, updateEnvs} from "../env/EnvSlice"
import {useDispatch, useSelector} from "react-redux";

export function TypeSelect()
{
    const env = useSelector(envSelector);
    // var step_cur = formFeedback.env.step_cur;

    const dispatch = useDispatch();

    function handleAnonym(event)
    {
        dispatch(updateEnvs({
            // name: "step_cur",
            // value: formFeedback.env.step_cur + 1,
            step_cur: 1,
            anonymous: true,
        }))
    }

    function handleOk() {
        dispatch(updateEnvs({
            // name: "step_cur",
            // value: formFeedback.env.step_cur + 1,
            step_cur: 1,
            anonymous: false,
        }))
    }

    function termsConfirm()
    {
        return Modal.confirm(
            {
                title: "Использование персональных данных",
                onOk: handleOk,
                okText: "Я согласен",
                cancelText: "Не согласен",
                content:
                    <Paragraph>
                        Мы используем различные технологии для сбора и хранения информации, когда вы посещаете сайт edit.ua. Это может включать в себя запись одного или нескольких cookie или анонимных идентификаторов. Мы также используем cookie и анонимные идентификаторы, когда вы взаимодействуете с услугами, предложенными нашими партнерами, например такими, как рекламные услуги, которые могут появиться на других сайтах.                    </Paragraph>
            }
        )

    }

    function handleNormal(event)
    {
        termsConfirm();
        // dispatch(updateEnvs({
        //     // name: "step_cur",
        //     // value: formFeedback.env.step_cur + 1,
        //     step_cur: 1,
        //     anonymous: false,
        // }))
    }

    return (
        <div>
            <Title level={2}>
                Мы будем очень признательны,
                если Вы поделитесь своими впечатлениями о нас!
            </Title>
            <Row gutter={[20,10]} justify="space-around" style={{marginTop:"20px"}}>
                <Col xs={24} sm={12}>
                    <Space direction="vertical" size="small">
                        <Button
                            onClick={handleNormal}
                            size={"large"} type="primary" shape="round" block>
                            Оценить
                        </Button>
                        <div>
                            <Title type="danger" style={{color:"#FC1010"}} strong>
                                5 %
                            </Title>
                            <Paragraph>
                                Оценить и оставить отзыв (наш подарок -5% стоимости следующей процедуры)                            </Paragraph>
                        </div>

                    </Space>
                </Col>
                <Col xs={24} sm={12}>
                    <Space direction="vertical" size="large">
                        <Button
                            onClick={handleAnonym}
                            size={"large"} type="default" shape="round" block>
                            Оценить анонимно
                        </Button>
                        <div>
                            <Paragraph >
                                Оценить анонимно работу рецепции, врача и общее впечатления о клинике.
                            </Paragraph>
                        </div>
                    </Space>
                </Col>
            </Row>
        </div>
    )
}