import {combineReducers} from 'redux'
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import {formFeedbackReducer} from './form/FormFeedbackSlice';
import {envReducer} from "./env/EnvSlice";

const rootPersistConfig = {
    key: 'root',
    storage: storage,
    // blacklist: ['form']
};

const envPersistConfig = {
    key: "env",
    storage: storage,
    blacklist: ['recorded'],
}

const rootReducer = combineReducers({
    formFeedback: formFeedbackReducer,
    // env: persistReducer(envPersistConfig, envReducer),
    env: envReducer,
})

// export default persistReducer(rootPersistConfig, rootReducer);
export default rootReducer;