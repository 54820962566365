import React from 'react';
import './App.less';
import { BrowserRouter as Router, Switch, Route}
    from 'react-router-dom'
import {Layout} from "antd";
import {ImpressionPage} from "./features/fd_Impression/ImpressionPage";
import {StepPage} from "./features/step/StepPage";


const {Header, Content, Footer} = Layout

function App() {
    return (
        <div className="App">
            <Router>

                        <Switch>
                            <Route exact path="/" component={StepPage}/>
                            <Route exact path="/impression" component={ImpressionPage}/>
                            <Route exact path="/step" component={StepPage}/>
                        </Switch>
            </Router>



        </div>
    );
}


export default App;
