import React, { useState, useEffect, useRef } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {incrementRecordSec} from "../env/EnvSlice";
import {useInterval} from "../../hooks/useInterval";

export function RecordTimer() {

    const dispatch = useDispatch();

    useInterval(() => {
        // dispatch(incrementRecordSec());
        dispatch(incrementRecordSec({}));
    }, 100);

    return <></>;
}
