import doc1 from "../assets/pics/doc1.jpg"
import doc2 from "../assets/pics/doc2.jpg"
import doc3 from "../assets/pics/doc3.jpg"
import doc4 from "../assets/pics/doc4.jpg"
import doc5 from "../assets/pics/doc5.jpg"
import doc6 from "../assets/pics/doc6.jpg"


/*
1. Как Вы цениваете работу рецепции
2. Как Вы оцениваете работу срециалиста
3. Ваше впечатление о клинике
 */

export const doctors = [
    {
        "id": 4,
        "full_name": "Янина Гнатенко",
        "position": "начмед",
        "bio": "начмед",
        "pic": doc1
    },
    {
        "id": 5,
        "full_name": "Анастасия Выговская",
        "position": "дерматолог",
        "bio": "дерматолог",
        "pic": doc2
    },
    {
        "id": 6,
        "full_name": "Анжела Петраш",
        "position": "ведущий специалист",
        "bio": "ведущий специалист",
        "pic": doc3
    },
    {
        "id": 7,
        "full_name": "Екатерина Труш",
        "position": "зав.отделением",
        "bio": "зав.отделением",
        "pic": doc4
    },
    {
        "id": 8,
        "full_name": "Анна Дзюба",
        "position": "дерматолог",
        "bio": "дерматолог",
        "pic": doc5
    },
    {
        "id": 9,
        "full_name": "Ангелина Малета",
        "position": "Врач дерматовенеролог",
        "bio": "Врач дерматовенеролог",
        "pic": doc6
    },
]

export const questions = [
    {
        id: 1,
        content: 'Как Вы цениваете работу рецепции?',
        rate: 1,
    },
    {
        id: 2,
        content: 'Как Вы оцениваете работу срециалиста?',
        rate: 1,
    },
]