import React, {useEffect, useRef, useState} from "react";
import Webcam from "react-webcam";
import {Button, Checkbox, Col, Form, Row, Slider, Space, Switch, Tag} from "antd";
import axios from 'axios';
import useResizeAware from 'react-resize-aware';

import {DeleteTwoTone, VideoCameraTwoTone, PlayCircleTwoTone, EyeTwoTone} from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {formFeedbackSelector, updateInput} from "../form/FormFeedbackSlice";
import {envSelector, updateEnv, updateEnvs} from "../env/EnvSlice"
import {RecordTimer} from "./RecordTimer";
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {ReactComponent as CirclePlay} from "../../assets/icons/play-circle-line.svg"
import {ReactComponent as CircleRecord} from "../../assets/icons/record-circle-line.svg"
import {ReactComponent as CircleStop} from "../../assets/icons/stop-circle-line.svg"
import {ReactComponent as CircleClose} from "../../assets/icons/close-circle-line.svg"


import {WebcamCustom} from "./WebcamCustom";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import {sec2timeMMSS} from "../../utils/time";

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};

export function RecordOld() {

    const webcamRef = React.useRef(null);
    const mediaRecorderRef = React.useRef(null);
    const [capturing, setCapturing] = React.useState(false);
    const [recordedChunks, setRecordedChunks] = React.useState([]);

    let videoBlob = {};

    const formFeedback = useSelector(formFeedbackSelector);
    const env = useSelector(envSelector);
    // var step_cur = formFeedback.env.step_cur;

    const videoRef = React.createRef();

    const dispatch = useDispatch();

    const vidRef = useRef(null);
    // const playRef= useRef(null);


    const [resizeListener, sizes] = useResizeAware();


    const handleStartCaptureClick = React.useCallback(() => {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm"
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();

        // set timer:
        dispatch(updateEnvs({recording: true}));
    }, [webcamRef, setCapturing, mediaRecorderRef]);




    const handleDataAvailable = React.useCallback(
        ({data}) => {
            if (data.size > 0) {
                const new_data = recordedChunks.concat(data);
                const blob = new Blob(new_data, {
                    type: "video/webm"
                });
                const url = URL.createObjectURL(blob);
                setRecordedChunks((prev) => prev.concat(data));
                dispatch(updateEnvs(
                    {
                        recordedChunks: new_data,
                        video_url: url,
                    }));
            }
        },
        [setRecordedChunks]
    );

    // const handleStopCaptureClick = React.useCallback(() => {
    //     mediaRecorderRef.current.stop();
    //     setCapturing(false);
    //     dispatch(updateEnv({
    //         name: "recorded",
    //         value: true,
    //     }));
    //     dispatch(updateEnvs({recording:false}));
    //
    //     // handlePlayRecorded();
    // }, [mediaRecorderRef, webcamRef, setCapturing]);


    function handlePlayRecorded () {

        // alert("play !");

        const blob = new Blob(recordedChunks, {
            type: "video/webm"
        });
        const url = URL.createObjectURL(blob);

        dispatch(updateEnv({
            name: "video_url",
            value: url,
        }));
    }

    const handleStopCapture = () => {
        mediaRecorderRef.current.stop();
        setCapturing(false);

        dispatch(updateEnvs(
            {
                recording:false,
                record_sec: Math.floor(env.record_step / 10),
                record_step: 0,
                recorded: true,
                recordedChunks: recordedChunks,
                // playing: true,
            }));
    }

    const handleDelRecorded = () => {
        videoBlob = null;
        setRecordedChunks([]);
        dispatch(updateEnvs({
            recorded: false,
            video_url: "",
            record_sec: 0,
            // playing: false,
        }));


    }

    function handleShowFace(event){
        dispatch(updateInput({
            name: "show_face",
            value: event.target.checked,
        }));
    }

    return (
        <div>
            {/*<Button*/}
            {/*    ref={playRef} size="large" type="link" onClick={handlePlayRecorded}>*/}
            {/*    <EyeTwoTone  style={{fontSize:"2rem"}}/>*/}
            {/*</Button>*/}
            <Space direction="vertical">
                <Row style={{width: "100%"}} justify="space-between">
                    <Col>
                        {/*<Form>*/}
                        {/*    <Form.Item label="Размыть лицо">*/}
                        {/*        <Switch*/}
                        {/*            checkedChildren={<CheckOutlined />}*/}
                        {/*            unCheckedChildren={<CloseOutlined />}*/}
                        {/*            checked={formFeedback.form.show_face}*/}
                        {/*            onChange={handleShowFace}*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}
                        {/*</Form>*/}

                    </Col>
                    <Col>
                        {/*<Tag color="red">*/}
                        {/*    {env.recording ?*/}
                        {/*        sec2timeMMSS(Math.floor(env.record_step / 10))*/}
                        {/*        :*/}
                        {/*        sec2timeMMSS(env.record_sec)*/}
                        {/*    }*/}
                        {/*</Tag>*/}
                    </Col>
                </Row>
                <div>
                    {resizeListener}
                    {!env.recorded ?
                        <div>
                            <Webcam
                                audio={true}
                                height={sizes.width / 2}
                                width={sizes.width - 60}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                            />
                            {/*<WebcamCustom*/}
                            {/*    audio={true}*/}
                            {/*    height={sizes.width / 2}*/}
                            {/*    width={sizes.width - 60}*/}
                            {/*    customRef={webcamRef}*/}
                            {/*    // screenshotFormat="image/jpeg"*/}
                            {/*    videoConstraints={videoConstraints}*/}
                            {/*/>*/}
                            {env.recording ? <RecordTimer/> : <></>}
                        </div>
                        :
                        // formFeedback.env.playing ?
                        <video
                            height={sizes.width / 2}
                            width={sizes.width - 60}
                            ref={vidRef} src={env.video_url} controls/>
                        // : <></>
                    }
                </div>
                {/*<div>*/}
                {/*    {!formFeedback.env.recorded ?*/}
                {/*        <Slider disabled={true} max={formFeedback.env.record_max * 10}*/}
                {/*                value={formFeedback.env.record_step}*/}
                {/*                step={0.1}*/}
                {/*                // tipFormatter={value => value * 10}*/}
                {/*                marks={{0: '00:00', 300: '00:30', 600:"01:00", 900: '01:30'}}>*/}
                {/*                /!*marks={new Map([[0, '00:00'], [record_max, '01:30']])} >*!/*/}

                {/*        </Slider>*/}
                {/*        : <></>}*/}
                {/*</div>*/}
            </Space>

            {capturing ? (
                <Space direction='horizontal'>
                    <Button size="large" type="link" onClick={handleStopCapture}>
                        {/*<VideoCameraTwoTone style={{fontSize: "2rem"}} twoToneColor="#eb2f96"/>*/}
                        <CircleStop className="video-icon" />
                    </Button>
                </Space>
            ) : (
                recordedChunks.length > 0 ? (
                        <Space direction='horizontal'>
                            <Button size="large"  type="link"  onClick={handleDelRecorded}>
                                <CircleClose fill="#e74c3c"/>
                            </Button>
                        </Space>
                    ) :
                    <Space direction='horizontal'>
                        <Button size="large" type="link" onClick={handleStartCaptureClick}>

                            <CircleRecord className="video-icon" />
                        </Button>
                    </Space>
            )}

            <Row justify="space-between">
                <Col>
                    <Form>
                        <Form.Item>
                            <Checkbox
                                checked={formFeedback.form.show_face}
                                onChange={handleShowFace}
                            >Размыть лицо</Checkbox>
                        </Form.Item>
                    </Form>
                </Col>
                <Col>
                    <Tag color="red">
                        {env.recording ?
                            sec2timeMMSS(Math.floor(env.record_step / 10))
                            :
                            sec2timeMMSS(env.record_sec)
                        }
                    </Tag>
                </Col>
            </Row>


        </div>
    )
}
