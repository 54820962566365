import React from "react";
import {Rate, Space, Form, Input, Radio, Row, Col} from "antd";
import Title from "antd/es/typography/Title";
import {useDispatch, useSelector} from "react-redux";
import {formFeedbackSelector, updateInput} from "../form/FormFeedbackSlice"
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/bootstrap.css'
// import 'react-phone-number-input/style.css'

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};


export function Phone()
{
    const dispatch = useDispatch();
    const {form} = useSelector(formFeedbackSelector);

    const handleChange = (event) => {
        // console.log('-------> change');
        event.preventDefault();
        dispatch(updateInput({
            name: event.target.name,
            value: event.target.value,
        }));
    }

    const handlePhoneChange = (phone) => {
        // console.log("phone + " + isValidPhoneNumber(phone))
        dispatch(updateInput({
            name: 'phone',
            value: phone,
        }));
    }

    const onFinish = values => {
        console.log('Success:', values);
        // dispatch(updateInput({
        //     name: 'phone',
        //     value: values.phone,
        // }))
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    return (
        <div>
            <Form
                {...layout}
                layout=""
                name="phone_form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    // label="Phone Number"
                    name="phone"
                    initialValue={form.phone}
                    rules={[{ required: true, message: 'Please provide your phone number!' }]}
                >
                    <Title level={2}>
                        Ваше номрер телефона?
                    </Title>
                    {/*<Input name="phone" value={form.phone} onChange={handleChange} size="large"*/}
                    {/*       style={{padding:"10px", borderRadius: "42px", fontSize: "2rem"}}/>*/}
                    <Space style={{textAlign:"left"}}>
                        <PhoneInput
                            country={'us'}
                            value={form.phone}
                            // containerStyle="material"
                            inputStyle={{borderRadius: "50px"}}
                            buttonStyle={{borderRadius: "50px"}}
                            onChange={phone => handlePhoneChange(phone)}
                        />
                    </Space>

                </Form.Item>
                <Form.Item>
                    <Title level={2}>
                        возраст?
                    </Title>
                    <Radio.Group name="age" value={form.age} onChange={handleChange}>
                        <Row justify="space-around">
                            {form.ages.map((age) => (
                                <Col key={age} xs={12} sm={6}>
                                    <Radio value={age}>{age}</Radio>
                                </Col>
                            ))}
                        </Row>

                    </Radio.Group>
                </Form.Item>
            </Form>
        </div>
    )
}
