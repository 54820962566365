import React from "react";
import Title from "antd/es/typography/Title";
import {Button, Rate, Space} from "antd";
import {FrownOutlined, MehOutlined, SmileOutlined} from '@ant-design/icons';

export function Feeling()
{
    return (
        <div>
            <Space direction="vertical" size="large">
                <Title>
                    Ваше впечатление о клинике?
                </Title>
                <Space>
                    <Button type="link" style={{fontSize:64}}>
                        <FrownOutlined />
                    </Button>
                    <Button type="link" style={{fontSize:64}}>
                        <MehOutlined />
                    </Button>
                    <Button type="link" style={{fontSize:64}}>
                        <SmileOutlined />
                    </Button>
                </Space>
            </Space>
        </div>
    )
}
