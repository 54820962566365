
export const SubmitStatus = {
    NONE: 0,
    FORM_START: 1,
    FORM_PROGRESS: 2,
    FORM_DONE: 3,
    FORM_FAILED: 5,
    FILE_START: 6,
    FILE_PROGRESS: 7,
    FILE_DONE: 8,
    FILE_FAILED: 9,
    ALL_DONE: 10,
    ALL_FAILED: 11,
}