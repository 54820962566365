import React from "react";
import {Avatar, Button, Card, Col, List, Row, Space} from 'antd';
import {formFeedbackSelector, updateInput} from "../form/FormFeedbackSlice";
import {useSelector, useDispatch} from "react-redux";
import Title from "antd/es/typography/Title";
import {envSelector} from "../env/EnvSlice";
import {Default, Mobile} from "../queries/Query";
import Paragraph from "antd/es/typography/Paragraph";

export function DoctorSelectCard()
{
    const dispatch = useDispatch();
    const {form} = useSelector(formFeedbackSelector);
    const env = useSelector(envSelector)

    const handleDoctorChange = (id) => {
        // // console.log('-------> change');
        dispatch(updateInput({
            name: 'doctor',
            value: id,
        }))
    }

    return (
        <div>
            <Mobile>
                <div >
                    <Title >У кого прохдили процедура?</Title>
                    <Row justify="space-around" gutter={[10,10]} style={{textAlign:"left"}}>
                        {env.doctors.map((item) => (
                            <Col key={item.id}>
                                <Card
                                    onClick={() => handleDoctorChange(item.id)}
                                    hoverable
                                    style={{ width: "220px" }}
                                    cover={
                                        <img
                                            style={{height: "120px", objectFit: "contain"}}
                                            alt="doctor" src={item.pic} />}
                                    bodyStyle={(item.id === form.doctor) ?
                                        {backgroundColor: "#C4E0E6"} : {}}
                                >
                                    <Card.Meta title={item.position}
                                               description={<Paragraph>{item.bio}<br/>{item.bio}</Paragraph>}
                                    />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Mobile>
            <Default>
                <div >
                    <Title >У кого проходили процедуру?</Title>
                    <Row justify="space-around" gutter={[10,10]} style={{textAlign:"left"}}>
                        {env.doctors.map((item) => (
                            <Col key={item.id}>
                                <Card
                                    onClick={() => handleDoctorChange(item.id)}
                                    hoverable
                                    style={{ width: "220px" }}
                                    cover={
                                        <img
                                            style={{height: "240px", objectFit: "contain"}}
                                            alt="doctor" src={item.pic} />}
                                    bodyStyle={(item.id === form.doctor) ?
                                        {backgroundColor: "#C4E0E6"} : {}}
                                >
                                    <Card.Meta title={item.full_name}
                                               description={<Paragraph>{item.position}<br/>{item.bio}</Paragraph>}
                                    />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Default>

        </div>

    );
}
