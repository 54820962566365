import {createSlice} from '@reduxjs/toolkit';
import {doctors} from "../../data/data";
import {axiosFeedback, axiosQuestions} from "../../services/axios"
import axios from "axios";
import {SubmitStatus} from "../../utils/dataStruct";

export const initialState = {
    form: {
        phone: '+380 ',
        ages: ['18 - 25', "16 - 35", "36 - 50", "51 - 85"],
        age: '',
        doctor: -1,
        show_face: false,
        questions: [],
        // questions: [
        //     {
        //         id: 1,
        //         content: 'Как Вы оцениваете работу рецепции?',
        //         rate: 0,
        //     },
        //     {
        //         id: 3,
        //         content: 'Как Вы оцениваете работу специалиста?',
        //         rate: 0,
        //     },
        //     {
        //         id: 2,
        //         content: 'Ваше впечатление о клинике?',
        //         rate: 0,
        //     },
        // ],
        // feeling: 0,
        video: null,
    },
}

const formFeedbackSlice = createSlice({
    name: 'formFeedback',
    initialState,
    reducers: {
        updateInput: (state, {payload}) => {
            state.form[payload.name] = payload.value
        },
        updateRate: (state, {payload}) => {
            // state = {...state, step_cur: payload.value}
            state.form.questions[payload.questionIndex].rate = payload.value
        },
        resetForm: (state, {payload}) => {
            // state.form.phone = "+39"
            const questions = state.form.questions
            questions.forEach(q => q.rate = 0);
            state.form = {...initialState.form, questions}},
        REHYDRATE: (state, {payload}) => {
            return {form:{...state.form}, env: {...state.env, recorded: false}}
        }
    }
});

export const formFeedbackSelector = state => state.formFeedback;
export const formFeedbackReducer = formFeedbackSlice.reducer
export const {updateInput, updateStep, updateRate,
    updateEnv, updateEnvs, resetForm, incrementRecordSec} =
    formFeedbackSlice.actions


export function fetchData(params) {

    // alert('fetching !');

    return async dispatch => {
        dispatch(updateEnv({
            name:"fetch",
            value: "started"
        }));
        // alert('fetching ! try');

        try {
            const response =
                await axiosFeedback.get('/doctors/',{params:params})
            dispatch(updateEnv({name: "doctors", value: response.data}));
            console.log('Success');
            dispatch(updateEnv({
                name:"fetch",
                value: "success"
            }));
        }
        catch (e) {
            console.log(e);
            dispatch(updateEnv({
                name:"fetch",
                value: "failed"
            }));
        }
    }
}

export function submitForm(feedback, videoFormData){

    return async dispatch => {

        function uploadProgress(progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            console.log(percentCompleted);
            dispatch(updateEnvs({
                upload_progress: percentCompleted,
            }));
        }

        dispatch(updateEnvs({
            submit: SubmitStatus.FORM_PROGRESS,
            upload_progress: 0,
        }));
        // const response = await axiosFeedback.post(
        //     '/feedbacks/', data);
        // dispatch(updateEnvs({
        //     upload_status: 'done',
        //     upload_progress: 0,
        // }));
        // console.log("Upload finished !");

        axiosFeedback.post('/feedbacks/', feedback,{
            headers: {
                'content-type': 'application/json',
                // 'enctype':"multipart/form-data",
            }
        })
            .then(res => {
                console.log(res.data);
                // dispatch(updateEnvs({
                //     submit: SubmitStatus.FORM_DONE,
                //     upload_progress: 0,
                // }));
                // console.log("upload start !");
                // dispatch(updateEnvs({
                //     submit: SubmitStatus.FILE_START,
                //     upload_progress: 0,
                // }));
                if (videoFormData !== null)
                {
                    console.log("upload start !");
                    dispatch(updateEnvs({
                        submit: SubmitStatus.FILE_PROGRESS,
                        upload_progress: 0,
                    }));
                    videoFormData.append('feedback', res.data.id);
                    axiosFeedback.post('/videos/', videoFormData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        onUploadProgress: uploadProgress,
                    })
                        .then(res => {
                            console.log(res.data);
                            dispatch(updateEnvs({
                                submit: SubmitStatus.ALL_DONE,
                                upload_progress: 101,
                            }));
                        })
                        .catch(err => {
                            console.log(err)
                            dispatch(updateEnvs({
                                submit: SubmitStatus.ALL_FAILED,
                                upload_progress: 0,
                            }));
                        });
                }
                else {
                    console.log("submit finish!");
                    dispatch(updateEnvs({
                        submit: SubmitStatus.ALL_DONE,
                        upload_progress: 0,
                    }));
                }
            })
            .catch(err => {
                console.log(err)
                dispatch(updateEnvs({
                    submit: SubmitStatus.ALL_FAILED,
                    upload_progress: 0,
                }));
            })
    }
}