import React, {useEffect} from "react";
import {Col, Modal, Row, Space, Spin} from "antd";
import {Question} from "../question/Question";
import {QuestionAnonym} from "../question/QuestionAnonym";
import {useDispatch, useSelector} from "react-redux";
import {formFeedbackSelector} from "../form/FormFeedbackSlice";
import {Phone} from "../phone/Phone";
import Text from "antd/es/typography/Text";
import {DoctorSelectCard} from "../doctors/DoctorSelectCard";
import {TypeSelect} from "../TypeSelect/TypeSelect";
import {RecordDialog} from "../record/RecordDialog";
import {Thanks} from "../thanks/Thanks";
import logo from "../../assets/pics/logo.png"
import {RecordOld} from "../record/RecordOld";
import {envSelector, updateEnvs, updateEnv, fetchData} from "../env/EnvSlice";
import {isValidPhoneNumber} from 'react-phone-number-input'
import {isIOS} from "react-device-detect"
import useResizeAware from "react-resize-aware";
import Title from "antd/es/typography/Title";

export function StepPage() {

    const formFeedback = useSelector(formFeedbackSelector);
    const env = useSelector(envSelector);

    const dispatch = useDispatch();
    // const [resizeListener, sizes] = useResizeAware();

    function warning(title, content) {
        Modal.warning({
            title: title,
            content: content,
            okText: "ОК",
        });
    }

    useEffect(() => {
        if (env.fetch === 'none')
            dispatch(fetchData())
    }, [])

    function handleTakeVideo()
    {
        dispatch(updateEnvs({
            step_cur: env.step_cur + 1,
            discount: true,
        }));
    }
    function handleNoTakeVideo()
    {
        dispatch(updateEnvs({
            step_cur: env.step_cur + 2,
            discount: false,
        }));
    }

    function getStep(step) {
        console.log("Step " + step);
        if (step === 0)
            return <TypeSelect/>
        else if (env.anonymous)
        {
            if (step === 1)
                return <DoctorSelectCard/>
            else if (step === 2)
                return <QuestionAnonym/>
            else if (step === 3)
            {
                // submitForm();
                return <Thanks discount={false}/>
            }

        }
        else if (step === 1)
            return <Phone/>
        else if (step === 2)
        {
            return <DoctorSelectCard/>
        }
        else if (step === (env.step_tot - 1))
        {
            if (isIOS)
                return <Thanks discount={true}/>
            else
                return <RecordDialog takeVideo={handleTakeVideo} noTakeVideo={handleNoTakeVideo}/>

        }
        else if (step === env.step_tot)
        {
            return <RecordOld/>
        }
        else if (step === env.step_tot + 1)
        {
            // submitForm();
            return <Thanks discount={env.discount}/>
        }
        else
            return <Question questionIndex={step - 3}/>
    }

    function handleNext(step)
    {
        if (env.anonymous)
        {
            let missed = false;
            formFeedback.form.questions.map(question => {
                if (question.rate === 0)
                    missed = true;
            })
            if (step === 1 && formFeedback.form.doctor < 0)
                return warning(
                    'Ошибка',
                    'Выбирайте специалиста');
            else if (step === 2 && missed)
                return warning(
                    "Ошибка",
                    "Оцените пожалуйста"
                    )
            else dispatch(updateEnv({
                name: "step_cur",
                value: step + 1,
            }));
        }
        else if (step === 1 && !isValidPhoneNumber('+' + formFeedback.form.phone))
            return warning("Ошибка", "Введите корректный номер телефона");
        else if (step === 1 && formFeedback.form.age === '')
            return warning("Ошибка", "Выбирайте категория возраста")
        else if (step === 2 && formFeedback.form.doctor < 0)
            return warning(
                'Ошибка',
                'Выбирайте специалиста');
        // else if (step === formFeedback.env.step_tot
        //     && formFeedback.env.record_sec < formFeedback.env.record_min)
        //     return warning(
        //         'Warning',
        //         'Please record a video between 00:30 and 01:30')
        else if ((step > 2 && step < env.step_tot - 1)
            && formFeedback.form.questions[step - 3].rate === 0)
        {
            return warning("Ошибка", "Оцените пожалуйста");
        }
        else dispatch(updateEnv({
            name: "step_cur",
            value: step + 1,
        }));
    }

    function handlePrev(event)
    {
        dispatch(updateEnv({
            name: "step_cur",
            value: env.step_cur - 1,
        }));
    }



    return (
        <>
            <div className="container-1200"
                 style={{
                     paddingTop: "24px",
                     paddingBottom: "24px",
                     textAlign: "center"}}>
                {/*<div className="step-container">*/}
                <Row justify="space-around" style={{marginBottom: "10px"}}>
                    <Col>
                        <img src={logo} style={{objectFit: "cover", width:"180px"}}/>
                    </Col>
                </Row>

                <Row justify="space-around" style={{maxHeight: "1vh"}}>
                    <Col md={20} sm={20}>
                        <Space
                            direction="vertical" size="small"
                            // style={{backgroundImage: `url(${logo_back})`}}
                            className="step-container">
                            {env.step_cur !== 0 ?
                            <Row justify="space-between">
                                {
                                    (!env.anonymous && env.step_cur <= env.step_tot)
                                    || (env.anonymous && env.step_cur < 3)?

                                <Col>
                                    <div className="step-corner step-corner-left">
                                        <Text strong style={{fontSize:"1.5rem"}}>
                                            {env.step_cur} / {env.anonymous?
                                            2 : env.step_tot}
                                        </Text>
                                    </div>
                                </Col> : <div></div>
                                }
                                {env.step_cur >= env.step_tot
                                    && env.discount?
                                <Col>
                                    <div className="step-corner step-corner-right">
                                        <Text strong style={{fontSize:"1.5rem"}}>5 %</Text>
                                    </div>
                                </Col>
                                    :
                                    <></>
                                }
                            </Row> :
                                <></>
                            }

                            <div
                                style={{margin: "32px"}}
                            >
                                {/*{resizeListener}*/}
                                <div>
                                    {/*{*/}
                                    {/*    if (env.fetch === 'progress')*/}
                                    {/*        return <Spin size="large" tip="Loading"/>*/}
                                    {/*    else if (env.fetch === 'done')*/}
                                    {/*        return getStep(env.step_cur);*/}
                                    {/*    else*/}
                                    {/*        return <Title style={{color:"#FC1010"}}>*/}
                                    {/*            Something went wrong !</Title>*/}
                                    {/*}*/}
                                    {env.fetch === 'progress' ?
                                        <Spin size="large" tip="Loading ..."/> :
                                        getStep(env.step_cur)
                                    }
                                </div>
                            </div>

                            {
                                // formFeedback.env.step_cur > 0
                                (!env.anonymous && env.step_cur > 0
                                && env.step_cur <= env.step_tot
                                && env.step_cur !== env.step_tot - 1)
                                || (env.anonymous
                                    && env.step_cur > 0
                                    && env.step_cur < 3)
                                    ?
                                <Row justify="space-between">
                                    <Col md={11} xs={11}>
                                        {/*<Button onClick={handlePrev} className="step-btn-next" block>Prev</Button>*/}
                                        {/*{formFeedback.env.step_cur < 1}*/}
                                        <button
                                            onClick={handlePrev}
                                            className="step-btn step-btn-prev" >
                                            <span>Назад </span>
                                        </button>
                                    </Col>
                                    <Col md={11} xs={11}>
                                        <button
                                            onClick={() => handleNext(env.step_cur)}
                                            className="step-btn step-btn-next" >
                                            <span>Далее</span>
                                        </button>
                                        {/*<Button type="primary" onClick={handleNext} block>Next</Button>*/}
                                    </Col>
                                </Row> : <div></div>
                            }
                        </Space>
                    </Col>
                </Row>

                {/*</div>*/}
            </div>
        </>
    )
}