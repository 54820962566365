import React from "react";
import {Col, Rate, Row, Space} from "antd";
import Title from "antd/es/typography/Title";
import {useDispatch, useSelector} from "react-redux";
import {formFeedbackSelector, updateRate} from "../form/FormFeedbackSlice";
import Paragraph from "antd/es/typography/Paragraph";

export function QuestionAnonym(props)
{
    const dispatch = useDispatch();
    const {form} = useSelector(formFeedbackSelector);

    const handleRateChange = (value, index) => {
        // console.log('-------> change');
        // event.preventDefault();
        dispatch(updateRate({
            questionIndex: index,
            value: value,
        }))
    }


    return (
        <div>
                {form.questions.map((question, index) =>
                    <Row key={index} gutter={[20,20]} justify="space-between">
                        <Col span={24}>
                            <Paragraph style={{fontSize: "1.2rem"}}>
                                {/*Как Вы цениваете работу рецепции?*/}
                                {/*{props.question.text}*/}
                                {question.content}
                            </Paragraph>
                        </Col>
                        <Col span={24}>
                            {/*<Title style={{textAlign: "center"}}>*/}
                                <Rate style={{fontSize: "1.8rem", textAlign:"center"}}
                                      onChange={(value) => handleRateChange(value, index)}
                                      value={form.questions[index].rate}/>
                            {/*</Title>*/}

                        </Col>
                    </Row>
                )}
        </div>
    )
}