import {createSlice} from '@reduxjs/toolkit';
import {doctors, questions} from "../../data/data";
import {axiosFeedback, axiosQuestions} from "../../services/axios"
import axios from "axios";
import {SubmitStatus} from "../../utils/dataStruct";
import {updateInput} from "../form/FormFeedbackSlice";

export const initialState = {
        fetch: 'none',
        anonymous: true,
        terms_accepted: false,
        discount: false,
        step_cur: 0,
        win_h: 0,
        win_w: 0,
        recording: false,
        recorded: false,
        playing: false,
        record_timer: null,
        record_step: 0,
        record_sec: 0,
        record_min: 30,
        record_max: 90,
        video_blob: {},
        video_url: "",
        // doctors: doctors/,
        // doctors: [],
        step_tot: 7,
        submit: SubmitStatus.NONE,
        upload_status: 'none',
        upload_progress: 0,
        recordedChunks: [],
}

const safeInitialState = {
    questions: [],
    doctors: [],
}

const totalInitialState = {...initialState, ...safeInitialState}

const envSlice = createSlice({
    name: 'env',
    initialState: totalInitialState,
    reducers: {
        updateEnv: (state, {payload}) => {
            state[payload.name] = payload.value
        },
        incrementRecordSec: (state, {payload}) => {
            state.record_step += 1;
        },
        updateEnvs: (state, {payload}) => {
            return  state = {...state, ...payload}
            // payload.keys.map(key => {state[key] = payload[key]});
        },
        resetEnv: (state, {payload}) => {
            // state.form.phone = "+39"
            return  {...state, ...initialState}},
    }
});

export const envSelector = state => state.env;
export const envReducer = envSlice.reducer
export const {updateEnv, updateEnvs, incrementRecordSec, resetEnv} =
    envSlice.actions


export function fetchData(params) {

    // alert('fetching !');

    const config = {
        // baseURL: "http://127.0.0.1:8000/feedback/",
        baseURL: "https://edit-clinic-feedback.herokuapp.com/feedback/",
        timeout: 10000,
        headers: {
            // 'Authorization': "JWT " + localStorage.getItem('access_token'),
            'Content-Type': 'application/json',
        }
    }

    const getDoctors = axios.get('/doctors/', config);
    const getQuestions = axios.get('/questions/', config);

    return async dispatch => {
        dispatch(updateEnvs({
            fetch: 'load',
        }));

        axios.all([getDoctors, getQuestions])
            .then(axios.spread((...responses) => {
                const resDoctors = responses[0];
                const resQuestions = responses[1];
                let questions = resQuestions.data
                questions.unshift({
                    id: -1,
                    content: 'Как Вы оцениваете работу специалиста?'})
                questions.forEach(q => {q.rate = 0});
                dispatch(updateEnvs({
                    doctors: resDoctors.data,
                    // questions: resQuestions.data,
                    fetch: 'done'}));
                dispatch(updateInput({
                    name: 'questions',
                    value: questions}))
           }))
            .catch(err => {
                console.log(err.response);
                console.log(err);
                dispatch(updateEnvs({fetch: 'failed'}))
            })
    }
}

export function submitForm(feedback, videoFormData){

    return async dispatch => {

        function uploadProgress(progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            console.log(percentCompleted);
            dispatch(updateEnvs({
                upload_progress: percentCompleted,
            }));
        }

        dispatch(updateEnvs({
            submit: SubmitStatus.FORM_PROGRESS,
            upload_progress: 0,
        }));
        // const response = await axiosFeedback.post(
        //     '/feedbacks/', data);
        // dispatch(updateEnvs({
        //     upload_status: 'done',
        //     upload_progress: 0,
        // }));
        // console.log("Upload finished !");

        axiosFeedback.post('/feedbacks/', feedback,{
            headers: {
                'content-type': 'application/json',
                // 'enctype':"multipart/form-data",
            }
        })
            .then(res => {
                console.log(res.data);
                // dispatch(updateEnvs({
                //     submit: SubmitStatus.FORM_DONE,
                //     upload_progress: 0,
                // }));
                // console.log("upload start !");
                // dispatch(updateEnvs({
                //     submit: SubmitStatus.FILE_START,
                //     upload_progress: 0,
                // }));
                if (videoFormData !== null)
                {
                    // console.log("upload start !");
                    dispatch(updateEnvs({
                        submit: SubmitStatus.FILE_PROGRESS,
                        upload_progress: 0,
                    }));
                    videoFormData.append('feedback', res.data.id);
                    axiosFeedback.post('/videos/', videoFormData, {
                        timeout: 0,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        onUploadProgress: uploadProgress,
                    })
                        .then(res => {
                            console.log(res.data);
                            dispatch(updateEnvs({
                                submit: SubmitStatus.ALL_DONE,
                                upload_progress: 101,
                            }));
                        })
                        .catch(err => {
                            console.log(err)
                            dispatch(updateEnvs({
                                submit: SubmitStatus.ALL_FAILED,
                                upload_progress: 0,
                            }));
                        });
                }
                else {
                    // console.log("submit finish!");
                    dispatch(updateEnvs({
                        submit: SubmitStatus.ALL_DONE,
                        upload_progress: 0,
                    }));
                }
            })
            .catch(err => {
                console.log(err)
                console.log(err.response)
                dispatch(updateEnvs({
                    submit: SubmitStatus.ALL_FAILED,
                    upload_progress: 0,
                }));
            })
    }
}