import React from "react"
import Title from "antd/es/typography/Title";
import {Button, Col, Row, Space} from "antd";

export function RecordDialog(props)
{
    return (
        <div>
            <Title level={2}>
                Запишите видео-отзыв и получите
            </Title>
            <Title level={2} strong>
                5% скидки
            </Title>
            <Title level={2}>
                на следующую процедуру!
            </Title>
            {/*<Space direction="vertical" style="large">*/}
            <Row gutter={[20,20]} justify="space-around">
                <Col span={24}>
                    <Button onClick={props.takeVideo} type="primary" size="large" block>
                        Хорошо , я хочу записать видео 1 мин
                    </Button>
                </Col>
                <Col span={24}>
                    <Button onClick={props.noTakeVideo} type="primary" danger size="large" block>
                        Нет , не хочу
                    </Button>
                </Col>
            </Row>


            {/*</Space>*/}
        </div>
    );
}

