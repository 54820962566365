import React, {useEffect} from "react";
import {Button, Progress, Rate, Space, Spin} from "antd";
import Title from "antd/es/typography/Title";
import {useDispatch, useSelector} from "react-redux";
import {formFeedbackSelector, resetForm} from "../form/FormFeedbackSlice";
import {resetEnv} from "../env/EnvSlice";
import {envSelector, submitForm} from "../env/EnvSlice"
import {blobToFile} from "../../services/fileService";
import {axiosFeedback} from "../../services/axios";
import {SubmitStatus} from "../../utils/dataStruct";


export function Thanks(props) {

    // const [recordedChunks, setRecordedChunks] = React.useState([]);


    const dispatch = useDispatch();
    const formFeedback = useSelector(formFeedbackSelector);
    const env = useSelector(envSelector)

    function handleHome() {
        dispatch(resetForm());
        dispatch(resetEnv());
    }

    useEffect(() => {
        let answers = [];
        let review_value = 0;
        formFeedback.form.questions.map(question => {
            if (question.id === -1)
                review_value = question.rate
            else
                answers.push({question: question.id, value: question.rate})
        });
        const feedback = {
            face: env.anonymous? false : formFeedback.form.show_face,
            anonymous: env.anonymous,
            review: {
                doctor: formFeedback.form.doctor,
                value: review_value,
            },
            answers: answers,
            discount: props.discount,
            video: !env.anonymous && env.recordedChunks.length > 0,
        }
        if (!env.anonymous)
            feedback.reviewer = env.anonymous? null : {
                phone: env.anonymous ? '' : formFeedback.form.phone,
                age_group: formFeedback.form.age,
            }
        let videoFormData = null;
        console.log("recorded " + env.recordedChunks.length)
        if (!env.anonymous && env.recordedChunks.length) {
            videoFormData = new FormData();
            const blob = new Blob(env.recordedChunks, {
                type: "video/webm"
            });
            var videoFile = blobToFile(blob, "video.webm");
            videoFormData.append('file', videoFile, "video.webm");
        }
        dispatch(submitForm(feedback, videoFormData));

    }, [])


    function getStatusView()
    {
        const stat = env.submit;
        if (stat === SubmitStatus.FORM_PROGRESS)
            return <Spin size="large" tip="Submitting ..."/>
        else if (stat === SubmitStatus.FILE_PROGRESS)
            return <div>
                <Progress type="circle" percent={env.upload_progress}/>
            </div>
        else if (stat === SubmitStatus.ALL_DONE)
            return <Space direction="vertical" size="large">
                <Title>
                    Спасибо!
                </Title>
                {props.discount ?
                    <>
                        <Title style={{color: "#FC1010"}}>
                            Ваша скидка 5%
                        </Title>
                        <Title>
                            ждет Вас на следующей процедуре!
                        </Title>
                    </>
                    :
                    <Title>
                        До следующих встреч!
                    </Title>
                }
                <Button type="primary" onClick={handleHome}>Завершить</Button>
            </Space>
        else if (stat === SubmitStatus.ALL_FAILED)
            return <Space  direction="vertical" size="large">
                <Title style={{color:"#fc1010"}}>
                    Failed to submit !
                </Title>
                <Button type="primary" onClick={handleHome}>Завершить</Button>
            </Space>
    }

    return (
        <>
            <div style={{minHeight: "200px"}}>
                {getStatusView()}
            </div>
        </>
    )
}
