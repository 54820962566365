import React, {useEffect, useRef, useState} from "react";
import Webcam from "react-webcam";
import {Button, Col, Form, Row, Slider, Space, Switch} from "antd";
import axios from 'axios';
import useResizeAware from 'react-resize-aware';

import {DeleteTwoTone, VideoCameraTwoTone, PlayCircleTwoTone, EyeTwoTone} from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {formFeedbackSelector, updateInput} from "../form/FormFeedbackSlice";
import {updateEnv, updateEnvs} from "../env/EnvSlice"
import {RecordTimer} from "./RecordTimer";
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {ReactComponent as CirclePlay} from "../../assets/icons/play-circle-line.svg"
import {ReactComponent as CircleRecord} from "../../assets/icons/record-circle-line.svg"
import {ReactComponent as CircleStop} from "../../assets/icons/stop-circle-line.svg"
import {ReactComponent as CircleClose} from "../../assets/icons/close-circle-line.svg"


import {WebcamCustom} from "./WebcamCustom";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};

export function Record() {

    const webcamRef = React.useRef(null);
    const mediaRecorderRef = React.useRef(null);
    const [capturing, setCapturing] = React.useState(false);
    // const [recordedChunks, setRecordedChunks] = React.useState([]);

    let videoBlob = {};

    const formFeedback = useSelector(formFeedbackSelector);
    // var step_cur = formFeedback.env.step_cur;

    const videoRef = React.createRef();

    const dispatch = useDispatch();

    const vidRef = useRef(null);
    // const playRef= useRef(null);


    const [resizeListener, sizes] = useResizeAware();


    const handleStartCaptureClick = React.useCallback(() => {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm"
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();

        // set timer:
        dispatch(updateEnvs({recording: true}));
    }, [webcamRef, setCapturing, mediaRecorderRef]);




    const handleDataAvailable = React.useCallback(
        ({data}) => {
            if (data.size > 0) {
                // setRecordedChunks((prev) => prev.concat(data));
                const recordedChunks = formFeedback.env.recordedChunks.concat(data);
                const blob = new Blob(recordedChunks, {
                    type: "video/webm"
                });
                const url = URL.createObjectURL(blob);

                // dispatch(updateEnv({
                //     name: "video_url",
                //     value: url,
                // }));
                dispatch(updateEnvs(
                    {
                        recordedChunks: recordedChunks,
                        video_url: url,
                    }));
            }
            // handlePlayRecorded();

        },
        // [setRecordedChunks]
        // [formFeedback.env.recordedChunks]
        []
    );

    // const handleStopCaptureClick = React.useCallback(() => {
    //     mediaRecorderRef.current.stop();
    //     setCapturing(false);
    //     dispatch(updateEnv({
    //         name: "recorded",
    //         value: true,
    //     }));
    //     dispatch(updateEnvs({recording:false}));
    //
    //     // handlePlayRecorded();
    // }, [mediaRecorderRef, webcamRef, setCapturing]);

    // let blob;
    // let url;
    function handlePlayRecorded () {

        // alert("play !");

        const blob = new Blob(formFeedback.env.recordedChunks, {
            type: "video/webm"
        });
        const url = URL.createObjectURL(blob);

        dispatch(updateEnv({
            name: "video_url",
            value: url,
        }));
    }

    const handleStopCapture = () => {
        mediaRecorderRef.current.stop();
        setCapturing(false);

        // const blob = new Blob(recordedChunks, {
        //     type: "video/webm"
        // });
        // const url = URL.createObjectURL(blob);
        // dispatch(updateEnv({
        //     name: "video_url",
        //     value: url,
        // }));
        // dispatch(updateEnv({
        //     name: "recorded",
        //     value: true,
        // }));
        dispatch(updateEnvs(
            {
                recording:false,
                record_sec: formFeedback.env.record_step / 10,
                record_step: 0,
                recorded: true,
                // recordedChunks: recordedChunks,
                // playing: true,
            }));

        // playRef.current.click();

        // handlePlayRecorded();
        // setTimeout(() => handlePlayRecorded(), 1000);

        // clearInterval(formFeedback.env.record_timer);
    }


    // useEffect(() => {
    //     if (formFeedback.env.playing)
    //     {
    //         handlePlayRecorded();
    //         handlePlayRecorded();
    //     }
    //
    // }, [formFeedback.env.playing])

    const handleDownload = React.useCallback(() => {
        if (formFeedback.env.recordedChunks.length) {
            const blob = new Blob(formFeedback.env.recordedChunks, {
                type: "video/webm"
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "react-webcam-stream-capture.webm";
            a.click();
            window.URL.revokeObjectURL(url);
            // setRecordedChunks([]);
            dispatch(updateEnvs({
                recordedChunks: [],
                // playing: false,
            }));
        }
    }, []); // recordedChunks

    function blobToFile(theBlob, fileName) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    const handleUpload = React.useCallback(() => {
        if (formFeedback.env.recordedChunks.length) {
            const blob = new Blob(formFeedback.env.recordedChunks, {
                type: "video/webm"
            });
            /*
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "react-webcam-stream-capture.webm";
            a.click();
            window.URL.revokeObjectURL(url);
            */

            var videoFile = blobToFile(blob, "test");
            let formData = new FormData();
            formData.append('file', videoFile, "test");
            let url = 'http://localhost:8000/feedback/videos/';

            axios.post(url, formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
                .then(res => {
                    console.log(res.data);
                })
                .catch(err => console.log(err.response))

            videoBlob = null;
            // setRecordedChunks([]);
        }
    }, []); // recordedChunks
    //
    // const handlePlayRecorded = () => {
    //
    //     const blob = new Blob(recordedChunks, {
    //         type: "video/webm"
    //     });
    //     const url = URL.createObjectURL(blob);
    //
    //     dispatch(updateEnv({
    //         name: "video_url",
    //         value: url,
    //     }));
    // }

    const handleDelRecorded = () => {
        videoBlob = null;
        // setRecordedChunks([]);
        dispatch(updateEnvs({
            recorded: false,
            video_url: "",
            recordedChunks: [],
            // playing: false,
        }));


    }

    function handleShowFace(checked, event){
        event.preventDefault();
        dispatch(updateInput({
            name: "show_face",
            value: checked,
        }))
    }

    return (
        <div>
            {/*<Button*/}
            {/*    ref={playRef} size="large" type="link" onClick={handlePlayRecorded}>*/}
            {/*    <EyeTwoTone  style={{fontSize:"2rem"}}/>*/}
            {/*</Button>*/}
            <Space direction="vertical">
                <Row>
                    <Col>
                        <Form>
                            <Form.Item label="Размыть лицо">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={formFeedback.form.show_face}
                                    onChange={handleShowFace}
                                />
                            </Form.Item>
                        </Form>

                    </Col>
                </Row>
                <div>
                    {resizeListener}
                    {!formFeedback.env.recorded ?
                        <div>
                            <Webcam
                                audio={true}
                                height={sizes.width / 2}
                                width={sizes.width - 60}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                            />
                            {/*<WebcamCustom*/}
                            {/*    audio={true}*/}
                            {/*    height={sizes.width / 2}*/}
                            {/*    width={sizes.width - 60}*/}
                            {/*    customRef={webcamRef}*/}
                            {/*    // screenshotFormat="image/jpeg"*/}
                            {/*    videoConstraints={videoConstraints}*/}
                            {/*/>*/}
                            {formFeedback.env.recording ? <RecordTimer/> : <></>}
                        </div>
                     :
                        // formFeedback.env.playing ?
                        <video
                        height={sizes.width / 2}
                        width={sizes.width - 60}
                        ref={vidRef} src={formFeedback.env.video_url} autoPlay controls/>
                        // <videoAuto
                        //     handlePlay={handlePlayRecorded}
                        //     height={sizes.width / 2}
                        //     width={sizes.width - 60}/>

                        // : <></>
                    }
                </div>
                <div>
                    {!formFeedback.env.recorded ?
                        <Slider disabled={true} max={formFeedback.env.record_max * 10}
                                value={formFeedback.env.record_step}
                                step={0.1}
                                // tipFormatter={value => value * 10}
                                marks={{0: '00:00', 300: '00:30', 600:"01:00", 900: '01:30'}}>
                                {/*marks={new Map([[0, '00:00'], [record_max, '01:30']])} >*/}

                        </Slider>
                        : <></>}
                </div>
            </Space>


            {capturing ? (
                <Space direction='horizontal'>
                    <Button size="large" type="link" onClick={handleStopCapture}>
                        {/*<VideoCameraTwoTone style={{fontSize: "2rem"}} twoToneColor="#eb2f96"/>*/}
                        <CircleStop className="video-icon" />
                    </Button>
                </Space>
            ) : (
                formFeedback.env.recordedChunks.length > 0 ? (
                        <Space direction='horizontal'>
                            <Button size="large"  type="link"  onClick={handleDelRecorded}>
                                {/*<UndoOutlined style={{fontSize:"2rem"}}/>*/}
                                {/*<DeleteTwoTone twoToneColor="#e74c3c" style={{fontSize:"2rem"}}/>*/}
                                <CircleClose fill="#e74c3c"/>
                            </Button>
                            <Button
                                // ref={playRef}
                                size="large" type="link" onClick={handlePlayRecorded}>
                                {/*<EyeTwoTone  style={{fontSize:"2rem"}}/>*/}
                                <CirclePlay className="video-icon"/>
                            </Button>
                        </Space>
                    ) :
                    <Space direction='horizontal'>
                        <Button size="large" type="link" onClick={handleStartCaptureClick}>
                            {/*<VideoCameraTwoTone style={{fontSize: "2rem"}}/>*/}
                            {/*<Icon compoent={circle_play}/>*/}
                            <CircleRecord className="video-icon" />
                        </Button>
                    </Space>
            )}
        </div>
    )
}