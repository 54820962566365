import axios from 'axios'

export const axiosFeedback = axios.create({
    // baseURL: "http://127.0.0.1:8000/feedback/",
    baseURL: "https://ed-clinic-fb.herokuapp.com/feedback/",
    timeout: 12000,
    headers: {
        // 'Authorization': "JWT " + localStorage.getItem('access_token'),
        'Content-Type': 'multipart/form-data',
        // 'accept': 'multipart/form-data'
    },
})

export const axiosQuestions = axios.create({
    baseURL: "http://127.0.0.1:8000/feedback/questions/",
    timeout: 5000,
    headers: {
        // 'Authorization': "JWT " + localStorage.getItem('access_token'),
        'Content-Type': 'multipart/form-data',
        'accept': 'multipart/form-data'
    },
})

