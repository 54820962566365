import React from "react";
import {Avatar, Button, List, Space} from 'antd';
import {formFeedbackSelector, updateInput} from "../form/FormFeedbackSlice";
import {useSelector, useDispatch} from "react-redux";

export function DoctorSelect()
{
    const dispatch = useDispatch();
    const {doctors, form} = useSelector(formFeedbackSelector);

    const handleDoctorChange = (value) => {
        // // console.log('-------> change');
        dispatch(updateInput({
            name: 'doctor',
            value: value,
        }))
    }

    return (
        <div style={{textAlign: 'left'}}>
            <List
                split={true}
                itemLayout="horizontal"
                dataSource={doctors}
                renderItem={item => (
                    <a>
                        <List.Item
                            // className="list-item"
                            onClick={() => handleDoctorChange(item.name)}
                            className={
                                 (item.name === form.doctor) ?
                                    "list-item-active" : "list-item"
                            }>
                            <List.Item.Meta
                                className="list-item-meta"
                                avatar={<Avatar src={item.avatar} size={"large"}/>}
                                title={<a href="#">{item.name}</a>}
                                description={item.position}
                            />
                        </List.Item>
                    </a>

                )}
            />
        </div>
    );
}
