import React from "react";
import Title from "antd/es/typography/Title";
import {Button, Col, Row, Space} from "antd";
import {Record} from "../record/Record";
import {Question} from "../question/Question";
import {Feeling} from "../feeling/Feeling";
import {useDispatch, useSelector} from "react-redux";
import {formFeedbackSelector, updateInput, updateStep} from "../form/FormFeedbackSlice";
import {updateEnvs} from "../env/EnvSlice"
import {Phone} from "../phone/Phone";
import {DoctorSelect} from "../doctors/DoctorSelect";
import {envSelector} from "../env/EnvSlice";

export function ImpressionPage() {
    const formFeedback = useSelector(formFeedbackSelector);
    const env = useSelector(envSelector)

    const dispatch = useDispatch();

    // const step_tot = formFeedback.step_tot - formFeedback.env.anonymous? 2 : 0;

    function getStep(step) {
        if (formFeedback.step_cur === 1)
            return <Phone/>
        else if (formFeedback.step_cur === 2)
            return <DoctorSelect/>
        else if (step === formFeedback.step_tot)
            return <Record/>
        else if (step === formFeedback.step_tot - 1)
            return <Feeling/>
        else
            return <Question questionIndex={step - 3}/>
    }

    function handleNext(event)
    {
        if (env.step_cur === env.step_tot)
            alert("Submit")
        else
        {
            dispatch(updateEnvs({step_cur: env.step_cur + 1}));
        }
    }
    function handlePrev(event)
    {
        if (formFeedback.step_cur === 1)
            alert("Submit")
        else
        {
            dispatch(updateEnvs({step_cur: env.step_cur - 1}));
        }
    }

    return (
        <>
            <div className="container-1200"
                 style={{paddingTop: "24px", paddingBottom: "24px", textAlign: "center"}}>
                <Space direction="vertical" size="large">
                    <Title level={0}>Your Impression about ID-Clinic</Title>
                    {/*<Record/>*/}
                    {/*<Question/>*/}
                    {/*<Feeling/>*/}

                    <div>
                        {getStep(formFeedback.step_cur)}
                    </div>
                    <Row justify="space-between" style={{marginTop:"92px"}}>
                        <Col>
                            <Button onClick={handlePrev}>Prev</Button>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={handleNext}>Next</Button>
                        </Col>
                    </Row>
                </Space>
            </div>
        </>
    )
}